import { useState, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { Text, Float } from '@react-three/drei';
import { useSpring } from 'framer-motion';

const OPENED_Y_POSITION = -0.2;
const CLOSED_Y_POSITION = -1.6;

function ClickButton({ isOpen, onClick }) {
  const buttonRef = useRef();
  const positionY = useSpring(OPENED_Y_POSITION, { duration: 1000, bounce: 0 });

  useFrame(() => {});

  useEffect(() => {
    if (!buttonRef) return;

    buttonRef.current.visible = !isOpen;
  }, [isOpen]);
  return (
    <Float ref={buttonRef} floatIntensity={3} speed={2} onClick={onClick} position-y={positionY.current}>
      <Text font={'/bangers-v20-latin-regular.woff'} bevelEnabled bevelSize={0.05}>
        Click
        <meshNormalMaterial />
      </Text>
    </Float>
  );
}

export default ClickButton;
