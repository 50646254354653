import { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { ContactShadows, Environment, Float, Html } from '@react-three/drei';
import { useSpring } from 'framer-motion';
import LoadingText from './LoadingText';
import ClickButton from './ClickButton';
import Laptop from './Laptop';
import { Dots } from './Demo1';

const ORIGIN_WEBSITE = 'https://browser-ui-for-website.vercel.app';

export default function Scene({ isOpen, setIsOpen, zoomLevel }) {
  const loadingTextRef = useRef();
  const clickButtonRef = useRef();
  const loadingTextOpacity = useSpring(1, {
    duration: 1500,
    bounce: 0,
    delay: 300,
  });
  const clickButtonOpacity = useSpring(0, {
    duration: 1000,
    bounce: 0,
    delay: 500,
  });

  useEffect(() => {
    loadingTextOpacity.set(0);
    clickButtonOpacity.set(1);
  });

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  useFrame(() => {
    if (loadingTextRef.current.children[0]) {
      if (loadingTextOpacity.current === 0) {
        loadingTextRef.current.visible = false;
      } else {
        loadingTextRef.current.children[0].fillOpacity =
          loadingTextOpacity.current;
      }

      clickButtonRef.current.position.z = 1;
      clickButtonRef.current.children[0].children[0].children[0].fillOpacity =
        clickButtonOpacity.current;
    }
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <mesh ref={loadingTextRef}>
        <LoadingText pNumber={100} opacity={loadingTextOpacity.current} />
      </mesh>

      <mesh ref={clickButtonRef}>
        <ClickButton onClick={handleClick} isOpen={isOpen}></ClickButton>
      </mesh>

      <Environment files="/potsdamer_platz_1k.hdr" />

      <color args={['black']} attach="background" />

      <Dots />

      <Float
        enabled={true}
        speed={0.5}
        floatingRange={[0, 0]} // Range of y-axis values the object will float within, defaults to
      >
        <Laptop
          url={ORIGIN_WEBSITE}
          isOpen={isOpen}
          zoomLevel={zoomLevel}
          visible={zoomLevel !== 0}
        ></Laptop>
      </Float>

      <Html
        fullscreen
        zIndexRange={[2147483647, 2147483647]}
        style={{ visibility: zoomLevel === 0 ? 'visible' : 'hidden' }}
        className={`${zoomLevel === 0 ? 'fadein' : 'fadeout'}}`}
      >
        <iframe
          src={ORIGIN_WEBSITE}
          allow="camera *;microphone *"
          style={{
            width: '100dvw',
            height: '100dvh',
            border: 'none',
          }}
        />
      </Html>

      <ContactShadows
        position-y={-0.8}
        opacity={0.4}
        scale={10}
        blur={2.4}
      ></ContactShadows>
    </>
  );
}
