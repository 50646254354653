import { Text } from '@react-three/drei';

const LoadingText = ({ pNumber }) => {
  return (
    <Text font={'/bangers-v20-latin-regular.woff'} anchorX='center' anchorY='middle'>
      {pNumber === 100 ? 100 : pNumber.toFixed(0)}%
    </Text>
  );
};

export default LoadingText;
