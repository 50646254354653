import { PresentationControls, useGLTF, Html, Float } from "@react-three/drei";
import { useState, useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useSpring, useMotionValueEvent } from "framer-motion";
import WavyText from "./WavyText";

const LOADING_TIMEOUT = 3000;
const LOADING_MESSAGES = [
  "Establishing connection... Diving into my digital world...",
  "Linking paths... Discover the architecture of my creations...",
  "Syncing thoughts... Navigating the landscapes of my code...",
  "Connecting... Unraveling the threads of my imagination...",
  "Engaging interfaces... Explore the blueprint of my mind's design...",
  "Buffering... Preparing for a journey through my coded reality...",
  "Establishing connection... Welcome to my world of code and beyond...",
  "Joining networks... Get ready to decode my digital life...",
  "Connecting... Traverse the pathways of my software universe...",
  "Initiating handshake... Preparing your journey through my digital ecosystem...",
];

export default function Laptop({ isOpen, url, zoomLevel, visible = true }) {
  const [isLoading, setIsLoading] = useState(true);
  const [showIframe, setShowIframe] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(LOADING_MESSAGES[0]);
  const { scene, nodes } = useGLTF("/model.gltf");
  const timeout = useRef(null);

  const controlsRef = useRef();

  const laptopScale = useSpring(0, { duration: 1000, bounce: 0 });
  const topRotationX = useSpring(isOpen ? 1.32 : 3.14, {
    duration: 1000,
    bounce: 0,
  });
  const positionY = useSpring(-1.6, { duration: 1000, bounce: 0 });
  const positionZ = useSpring(0, { duration: 1000, bounce: 0 });

  const resetControls = () => {
    if (!controlsRef.current) return;

    const controls = controlsRef.current;
    controls.reset();
  };

  useEffect(() => {
    if (!isOpen) {
      if (timeout.current) clearTimeout(timeout.current);
      setIsLoading(true);
    }
    if (isOpen) {
      setLoadingMessage(
        LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]
      );
      timeout.current = setTimeout(() => {
        if (!isOpen) return;
        if (!isLoading) return;
        setIsLoading(false);
        timeout.current = null;
      }, LOADING_TIMEOUT);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      switch (zoomLevel) {
        case 0:
          topRotationX.set(1.32);
          positionY.set(-1.4);
          positionZ.set(5);
          break;
        case 1:
          topRotationX.set(1.32);
          positionY.set(-1.4);
          positionZ.set(3.6);
          break;

        case 2:
          topRotationX.set(1.32);
          positionY.set(-1.2);
          positionZ.set(1);
          break;

        case 3:
          topRotationX.set(1.32);
          positionY.set(-1.2);
          positionZ.set(-2);
          break;

        default:
          break;
      }
    } else {
      topRotationX.set(3.14);
      positionY.set(-1.6);
      positionZ.set(0);
    }
  }, [isOpen, zoomLevel, resetControls]);

  useMotionValueEvent(topRotationX, "change", () => {
    // Opened
    if (topRotationX.current === 1.32) {
      nodes["FrontCameraRing001"].visible = true;
      setShowIframe(true);
    } else {
      nodes["FrontCameraRing001"].visible = false;
      setShowIframe(false);
    }
  });

  useFrame(() => {
    scene.scale.x = laptopScale.current;
    scene.scale.y = laptopScale.current;
    scene.scale.z = laptopScale.current;

    nodes["Top"].rotation.x = topRotationX.current;

    scene.position.y = positionY.current;
    scene.position.z = positionZ.current;
  });

  // Initial state
  useEffect(() => {
    nodes["FrontCameraRing001"].visible = false;
    laptopScale.set(1);
  }, []);

  return (
    <>
      <PresentationControls
        global
        cursor
        snap
        polar={[0, isOpen ? 0 : Math.PI / 8]}
        azimuth={[-Math.PI / 4, Math.PI / 4]}
        zoom={1.5}
        speed={1}
        rotation={[0, 0, 0]}
      >
        <primitive object={scene}>
          {showIframe && (
            <Html
              dispose={null}
              transform
              wrapperClass="htmlScreen"
              distanceFactor={1.17}
              position={[0, 1.56, -1.4]}
              rotation-x={-0.25}
              visible={visible}
            >
              {/* Allow camera and microphone access within the context of this iframe */}
              <div
                className={`laptop-loading ${isLoading ? "fadein" : "fadeout"}`}
                style={{ visibility: visible ? "visible" : "hidden" }}
              >
                <WavyText text={loadingMessage} delay={0.2} duration={0.01} />
              </div>
              <iframe
                src={url}
                allow="camera *;microphone *"
                style={{ opacity: isLoading || !visible ? 0 : 1 }}
              />
            </Html>
          )}
        </primitive>
      </PresentationControls>
    </>
  );
}
