import FullScreenIcon from './assets/fullscreen.svg';
import InfoCircleIcon from './assets/info-circle.svg';
import ZoomInIcon from './assets/zoomin.svg';
import ZoomOutIcon from './assets/zoomout.svg';
import useFullscreen from './hooks/useFullscreen';

const OverlayActions = ({
  isOpen = false,
  isHidden,
  onChangeZoom = () => {},
  zoomLevel,
  onClick,
}) => {
  const [_, toggleFullscreen] = useFullscreen();

  const handleToggleInfoModal = () => {
    onClick();
  };

  const handleZoomIn = () => {
    onChangeZoom('+');
  };
  const handleZoomOut = () => {
    onChangeZoom('-');
  };

  const disabledZoomIn = zoomLevel === 0;
  const disabledZoomOut = zoomLevel === 3;

  return (
    <div
      className="overlay-actions fade-animation"
      style={{
        display: isOpen && !isHidden ? 'block' : 'none',
      }}
    >
      <div>
        <button onClick={toggleFullscreen ?? undefined}>
          <img src={FullScreenIcon} alt="fullscreen" width="24" height="24" />
        </button>

        <button
          onClick={handleZoomIn}
          disabled={disabledZoomIn}
          style={{ opacity: disabledZoomIn ? 0.2 : 1 }}
        >
          <img src={ZoomInIcon} alt="zoom-in" width="24" height="24" />
        </button>

        <button
          onClick={handleZoomOut}
          disabled={disabledZoomOut}
          style={{ opacity: disabledZoomOut ? 0.2 : 1 }}
        >
          <img src={ZoomOutIcon} alt="zoom-out" width="24" height="24" />
        </button>

        <button onClick={handleToggleInfoModal}>
          <img src={InfoCircleIcon} alt="info circle" width="24" height="24" />
        </button>
      </div>
    </div>
  );
};

export default OverlayActions;
