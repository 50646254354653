import imgUrl from './assets/infoimage.webp';

const OvelayInfo = ({ isOpen = false, onClick }) => {
  return (
    <div
      className='info fade-animation-2'
      style={{
        display: isOpen ? 'block' : 'none',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <img src={imgUrl} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
    </div>
  );
};

export default OvelayInfo;
