const OverlayWarn = ({ showWarning = false }) => {
  if (!showWarning) return;
  return (
    <div className='rotate-warn rotate-warn-animation'>
      <span>Please rotate your phone to landscape</span>
    </div>
  );
};

export default OverlayWarn;
