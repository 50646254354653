import { Suspense, useMemo, useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import Scene from './Scene.jsx';
import Loading from './Loading.jsx';
import { isMobileView } from './helper';
import useWindowSize from './hooks/useWindowSize';
import OverlayWarn from './OverlayWarn';
import OverlayInfo from './OverlayInfo';
import OverlayActions from './OverlayActions';
import useToggle from './hooks/useToggle';
import './style.css';

const ENABLE_PERFORMANCE_PANEL = false;

const App = () => {
  const isMouseDown = useRef(false);
  const isMouseDragging = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const originPosition = useRef([0, 0]);
  const [isMobile, setIsMobile] = useState(isMobileView());
  const [zoomLevel, setZoomLevel] = useState(2);
  const [infoState, toggleInfo] = useToggle();

  const size = useWindowSize();

  const showWarning = useMemo(() => {
    const portrait = window.matchMedia('(orientation: portrait)').matches;

    return isMobile && (size.width < size.height || portrait);
  }, [size, isMobile]);

  useEffect(() => {
    setIsMobile(isMobileView());

    if (isMobileView()) {
      setIsOpen(false);
    }
  }, [size]);

  const handleToggleInfo = () => {
    setZoomLevel(2);
    setTimeout(() => {
      toggleInfo();
    }, 1000);
  };

  const handleInfoClick = () => {
    toggleInfo();
  };

  const handleChangeZoom = (state) => {
    if (state === '+') {
      if (zoomLevel === 0) return;
      setZoomLevel(zoomLevel - 1);
    } else if (state === '-') {
      if (zoomLevel === 3) return;
      setZoomLevel(zoomLevel + 1);
    }
  };

  return (
    <>
      <OverlayWarn showWarning={showWarning} />
      <OverlayInfo isOpen={infoState} onClick={handleInfoClick} />
      <OverlayActions
        isOpen={isOpen}
        isHidden={infoState}
        zoomLevel={zoomLevel}
        onChangeZoom={handleChangeZoom}
        onClick={handleToggleInfo}
      />

      <div className='canvas-container'>
        <Canvas
          dpr={[1, 2]}
          camera={{ fov: 45, near: 0.1, far: 2000 }}
          style={{
            touchAction: 'none',
          }}
          onMouseDown={(event) => {
            event.preventDefault();

            originPosition.current = [event.clientX, event.clientY];
            isMouseDown.current = true;
          }}
          onMouseMove={() => {
            event.preventDefault();

            const isMovedMoreThan4Px =
              originPosition.current[0] - event.clientX > 4 || originPosition.current[1] - event.clientY > 4;
            if (isMouseDown.current && isMovedMoreThan4Px) isMouseDragging.current = true;
          }}
          onMouseUp={() => {
            event.preventDefault();
            if (isMouseDown.current && !isMouseDragging.current) {
              setIsOpen(false);
            }

            // Reset
            isMouseDown.current = false;
            isMouseDragging.current = false;
            originPosition.current = [0, 0];
          }}
        >
          {ENABLE_PERFORMANCE_PANEL && <Perf position='top-left' />}
          <Suspense fallback={<Loading />}>
            <Scene isOpen={isOpen} setIsOpen={setIsOpen} zoomLevel={zoomLevel} />
          </Suspense>
        </Canvas>
      </div>
    </>
  );
};

export default App;
